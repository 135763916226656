import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'

const app = createApp(App)

app.use(Quasar, quasarUserOptions)
app.use(createPinia())
app.use(router)

app.mount('#app')
